<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketchjs="https://sketch.io/dtd/" version="1.1" height="40" width="40" viewBox="0 0 1166 369">
    <path style="fill: #ff7526; stroke: #ff7526; mix-blend-mode: source-over; paint-order: stroke fill markers; fill-opacity: 1; stroke-dasharray: none; stroke-dashoffset: 0; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-opacity: 1; stroke-width: 2; vector-effect: non-scaling-stroke;" sketchjs:tool="square" d="M0 0 L159.4 0 159.4 159.4 0 159.4 z" transform="matrix(2.177353872565215,0,0,2.177353872565208,4.636103707938247,9.558869383054724)"/>
    <path style="fill: #000000; stroke: #000000; mix-blend-mode: source-over; paint-order: stroke fill markers; fill-opacity: 1; stroke-dasharray: none; stroke-dashoffset: 0; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-opacity: 1; stroke-width: 2; vector-effect: non-scaling-stroke;" sketchjs:tool="circle" d="M72.868 0 C113.111 0 145.735 32.624 145.735 72.868 145.735 113.111 113.111 145.735 72.868 145.735 32.624 145.735 0 113.111 0 72.868 0 32.624 32.624 0 72.868 0 z" transform="matrix(2.3844846604577232,-0.007595341796490779,0.007595341796490756,2.384484660457716,406.4372990353698,13.034993816157943)"/>
    <path style="fill: #000000; stroke: #000000; mix-blend-mode: source-over; paint-order: stroke fill markers; fill-opacity: 1; stroke-dasharray: none; stroke-dashoffset: 0; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-opacity: 1; stroke-width: 2; vector-effect: non-scaling-stroke;" sketchjs:tool="circle" d="M72.868 0 C113.111 0 145.735 32.624 145.735 72.868 145.735 113.111 113.111 145.735 72.868 145.735 32.624 145.735 0 113.111 0 72.868 0 32.624 32.624 0 72.868 0 z" transform="matrix(2.3844846604577232,-0.007595341796490779,0.007595341796490756,2.384484660457716,809.357970215113,9.170435741864313)"/>
  </svg>
</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
